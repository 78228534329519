import React from 'react';
import './privacy-policy.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import { TranslatedBlock } from '../components/language';
import Annotation from 'src/components/annotation';
import Layout from 'src/components/layout';

export default class PrivacyPolicy extends React.Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setPage('privacy-policy');
  }

  render() {
    return (
      <Layout
        title={{
          english: 'Privacy policy',
          french: 'Politique de confidentialité des renseignements personnels',
        }}
        showTab={true}
      >
        <TranslatedBlock language="english">
          <div className="privacy-policy">
            <h2>Roche Canada Online Privacy Notice</h2>
            <h3>General Privacy Statement</h3>
            <p>
              At Hoffmann-La Roche Limited (“Roche”) we are committed to protecting your personal information. This
              Privacy Notice outlines the types of personal information Roche may collect; the means by which Roche may
              collect, use, or share your personal information; steps Roche takes to protect your personal information;
              and choices you are provided with respect to the use of your personal information.
            </p>
            <p>
              For purposes of this Privacy Notice, “Personal Data” is any information by which you can be individually
              identified both directly and indirectly, including, but not limited to, your name, address, e-mail
              address, and telephone number.
            </p>
            <h3>How and Why We Process Your Personal Data</h3>
            <p>
              We may request Personal Data about you. Examples of Personal Data that we may collect that directly
              identifies you includes your name, contact information, email address, and other information in
              combination with these identifiers. On our websites, we also may collect certain Personal Data information
              that does not directly identify you by name, but could be used to identify that a specific computer or
              device has accessed our website.
            </p>
            <p>We process your Personal Data for many reasons, including:</p>
            <ul>
              <li>To respond to your requests or inquiries;</li>
              <li>To complete a transaction;</li>
              <li>To track and monitor adverse events and other activities related to pharmacovigilance;</li>
              <li>To meet legal, regulatory and security processing requirements;</li>
              <li>To personalize your experience on our website(s);</li>
              <li>To perform website analytics and measure website performance; and</li>
              <li>To maintain our website(s), including for security purposes.</li>
            </ul>
            <h3>More Information on our Processing Activities</h3>
            <p>
              <u>Responding to Requests or Inquiries:</u> Personal data may be submitted to us for various purposes,
              including through direct interactions, via our website(s) and other online resources. For example, you may
              submit a Medical Information request, inquire about a product, or subscribe to one of our mailing lists.
              In these instances, we may use the information that you provide to us to take the steps necessary to
              respond to your request. Depending on your request, we may collect your contact information (such as your
              name, mailing address, telephone number, job title), your interests and preferences (such as products or
              areas of interest), and any other information you provide to us. If reporting is required, we may process
              your data to comply with our legal obligations.
            </p>
            <p>
              <u>Completing Transactions:</u> Your Personal Data may be collected to fulfill services that you have
              requested and to understand your interests and preferences. In these instances, we may collect your
              contact information as well as a history of your previous transactions with us (such as order history,
              customer account information). If you are purchasing or ordering a product or service from us, we use this
              information to perform our agreement with you.
            </p>
            <p>
              <u>Personalizing Your Experience:</u> We may collect certain information about you, your preferences, and
              how you have interacted with us in the past in order to understand your interest in our products and
              services so that we can best serve you. This may include information about your contact and product
              preferences, languages, marketing preferences, and demographic data. In cases where we collect this
              information automatically, we collect and process this information for our legitimate business interests.
              In other cases, we will collect and process this information pursuant to your consent.
            </p>
            <p>
              <u>Website Analytics and Tracking:</u> Where permitted by law, we may combine Personal Data you provide
              with other information you’ve provided to Roche through our websites. Where permitted by law, we may also
              combine Personal Data collected through our websites and online resources with Roche’s offline records and
              information provided to us by third parties. More information about this can be found in the section below
              “Cookies and Other Web Trackers”.
            </p>
            <p>
              <u>Pharmacovigilance and Medical Inquiries:</u> Any personal data provided to Roche related to adverse
              events or other activities related to pharmacovigilance will be used solely for these purposes. This
              information is very important for public health and will be used for the detection, assessment,
              understanding and prevention of adverse effects or any other medicine-related problem. We collect and
              process your data for these purposes in accordance with specific GVP (Pharmacovigilance) legislation and
              in order to comply with our legal obligations. Any personal data provided to Roche related to a medical
              inquiry may be used to answer the inquiry, follow up on such requests and maintain the information in a
              Medical Information database for reference. Where required by law (such as for pharmacovigilance), we may
              also be required to report the data to regulatory authorities. Your data will not be used for any other
              purpose.
            </p>
            <h3>No Third-Party Direct Marketing Use</h3>
            <p>
              We will not sell or otherwise transfer the personally identifiable information you provide to us to any
              third parties for their own direct marketing use unless we provide clear notice to you and obtain your
              explicit consent for your data to be shared in this manner.
            </p>
            <h3>Recipients of your Personal Data</h3>
            <p>
              We may share your Personal Data with Roche’s affiliates around the world. Our Roche affiliates will use
              your Personal Data for the same purposes as we do. A list of Roche’s affiliates is available in the
              current annual report, which can be found in the Investors section of www.roche.com.
            </p>
            <p>
              We may also share your Personal Data with outside third parties, including our data processors, for the
              following purposes:
            </p>
            <ul>
              <li>To help fulfill Roche business transactions;</li>
              <li>To conduct technical maintenance of our websites and other web platforms;</li>
              <li>
                To facilitate a merger, consolidation, transfer of control or other corporate reorganization in which
                Roche participates, or pursuant to a financial arrangement undertaken by Roche;
              </li>
              <li>
                To respond to appropriate requests of legitimate government authorities, or where required by applicable
                laws, court orders, or government regulations; and
              </li>
              <li>Where needed for corporate audits or to investigate or respond to a complaint or security threat</li>
            </ul>
            <h3>International Transfers of Your Personal Data</h3>
            <p>
              Any Personal Data you provide to us may be transferred to or stored in a geographic region which imposes
              different privacy obligations than your country of origin. This may mean that your Personal Data may be
              sent to a country with less restrictive data protection laws than your own. Any such transfer will be
              conducted in compliance with applicable law and Personal Data may be subject to the local laws of the
              jurisdictions within which it is collected, used, disclosed and/or stored, and may be accessed by
              governmental and law enforcement authorities in those jurisdictions.
            </p>
            <h3>Retention / Storage Period of Your Personal Data</h3>
            <p>
              The length of time in which we will store your Personal Data will differ depending on the purpose for
              which we have collected and are processing your data. In most cases, we will keep the data for three (3)
              years following our last interaction with you. We may, however, maintain your data for a longer period of
              time if we are required by law to maintain your data. For example, reports related to pharmacovigilance
              are kept for a minimum of 10 years after the withdrawal of the product in the last country where the
              product is marketed.
            </p>
            <h3>Our Websites</h3>
            <p>
              We use information to secure our websites, network systems, and other assets. This may include information
              concerning your IP Address, geographic location, resources you have accessed, and similar information. We
              collect this information automatically, for our legitimate business interests. Several places on our
              website require Personal Data if you choose to use them, including surveys, registration, and content
              sharing features (i.e., “E-mail to a Friend” links). Roche and its business partners collect this
              information about you only if you voluntarily provide it to us. Please be aware that certain features of
              this site may not be available to you if you elect not to provide certain Personal Data. Any Personal Data
              you provide to us will be used in accordance with this Privacy Notice.
            </p>
            <b>Cookies and Other Web Trackers</b>
            <p>
              Our websites use technology such as cookies, web beacons and other tracking technologies that enable us to
              deliver content that is tailored to your interests and preferences based on your past activity on the
              site. Please see our Cookie Policy for more information.
            </p>
            <h3>Websites We Do Not Own or Control</h3>
            <p>
              Our websites also may contain links to third-party websites. We do not endorse and are not responsible for
              the content of third-party websites or resources, and our privacy notice does not apply to any sites that
              are not affiliated with Roche, even if you access them via a link on our site. You should review the
              privacy policies of any third-party site before providing any information.
            </p>
            <h3>Minors</h3>
            <p>
              We do not knowingly collect any Personal Data from anyone under the age of 16 without the prior,
              verifiable consent of a legal representative. Such legal representative may have the right, upon request,
              to view the information provided by the child and require that it be deleted. Moreover, all minors should
              seek their parent’s or guardian’s (legal representative) permission prior to using or disclosing any
              Personal Data on our website or online resource. This website is not designed or intended for use by
              children under the age of 16.
            </p>
            <h3>Data Security</h3>
            <p>
              Roche uses technology and security precautions, rules and other procedures to protect your personal
              information from unauthorized access, improper use, disclosure, loss or destruction. To ensure the
              confidentiality of your information, Roche uses also industry standard firewalls and password protection.
              It is, however, your personal responsibility to ensure that the computer you are using is adequately
              secured and protected against malicious software, such as trojans, computer viruses and worm programs. You
              are aware of the fact that without adequate security measures (e.g. secure web browser configuration,
              up-to-date antivirus software, personal firewall software, no usage of software from dubious sources)
              there is a risk that the data and passwords you use to protect access to your data, could be disclosed to
              unauthorized third parties.
            </p>
            <h3>Access</h3>
            <p>
              You have a right to request access to your Personal Data and to request a correction to it if you believe
              it is inaccurate. In the event that you believe that your Personal Data is not accurate or you wish access
              to your Personal Data, you may make a request using the contact information provided below.
            </p>
            <p>
              We endeavor to provide timely access to your Personal Data. However, we may require you to verify your
              identity to our satisfaction prior to doing so. Further, there may be circumstances where access cannot be
              granted. For example, we will not grant access where doing so would lead to the disclosure of Personal
              Data of another individual and that individual refuses to provide consent to the disclosure, or where the
              information is subject to privilege or other legal restrictions. In such cases you will be notified of the
              reason why it is not possible to grant access to your Personal Data.
            </p>
            <h3>Updates to This Privacy Notice</h3>
            <p>
              From time to time, we may revise this Privacy Notice. Any such changes to this Privacy Notice will be
              reflected on this page. Roche recommends that you review this Privacy Notice regularly for any changes.
              The date on which this notice was last revised is located at the top of this notice.
            </p>
            <h3>How to Contact Roche</h3>
            <p>
              For questions or if you wish Roche to amend or delete your profile, please contact us in writing (by
              post), as follows:
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Pharmaceuticals Division
                      <br />
                      Privacy Officer
                      <br />
                      7070 Mississauga Road
                      <br />
                      Mississauga, Ontario
                      <br />
                      L5N 5M8
                      <br />
                      Tel.: 1-800-561-1759
                    </p>
                  </td>
                  <td>
                    <p>
                      Diagnostics Division
                      <br />
                      Privacy Officer
                      <br />
                      201 Armand-Frappier Blvd
                      <br />
                      Laval, Quebec
                      <br />
                      H7V 4A2
                      <br />
                      Tel.: 1-800-361-2070
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <p>
              This website contains materials intended only for residents of Canada, and may contain product details or
              information not available or valid outside of Canada. We accept no responsibility for users outside of
              Canada accessing portions of this website which may be invalid or non-compliant with the laws, regulations
              or standards of the user’s home country.
            </p>
          </div>
        </TranslatedBlock>
        <TranslatedBlock language="french">
          <div className="privacy-policy">
            <h2>Énoncé de confidentialité en ligne de Roche Canada</h2>
            <h3>Énoncé de confidentialité général</h3>
            <p>
              Chez Hoffmann-La Roche Limited/Limitée (« Roche »), nous sommes déterminés à protéger vos renseignements
              personnels. Le présent énoncé de confidentialité décrit le type de renseignements personnels que Roche
              peut recueillir; les moyens par lesquels Roche peut recueillir, utiliser ou divulguer vos renseignements
              personnels; les mesures que Roche prend pour protéger vos renseignements personnels; les choix dont vous
              disposez quant à l’utilisation de vos renseignements personnels.
            </p>
            <p>
              Aux fins du présent énoncé de confidentialité, on entend par « renseignements personnels » tout
              renseignement permettant de vous identifier personnellement, que ce soit directement ou indirectement,
              notamment votre nom, votre adresse, votre adresse électronique et votre numéro de téléphone.
            </p>
            <h3>Pourquoi et comment traitons-nous vos renseignements personnels?</h3>
            <p>
              Il se peut que nous sollicitions des renseignements personnels à votre sujet. Les renseignements
              personnels que nous pouvons recueillir à votre sujet comprennent votre nom, vos coordonnées, votre adresse
              électronique, ainsi que d’autres renseignements en combinaison avec ces identifiants. Sur nos sites Web,
              il se peut aussi que nous recueillions certains renseignements personnels ne permettant pas de vous
              identifier directement par votre nom, mais pouvant être utilisés pour déterminer qu’un ordinateur ou un
              appareil en particulier s’est connecté à notre site.
            </p>
            <p>Nous traitons vos renseignements personnels à plusieurs fins, notamment:</p>
            <ul>
              <li>pour répondre à vos demandes et à vos questions;</li>
              <li>pour effectuer une transaction;</li>
              <li>
                pour surveiller les manifestations indésirables, ainsi que dans le cadre d’autres activités liées à la
                pharmacovigilance;
              </li>
              <li>
                pour satisfaire aux exigences de traitement sur le plan juridique, réglementaire et de la sécurité;
              </li>
              <li>pour personnaliser votre expérience sur nos sites Web;</li>
              <li>pour effectuer des analyses sur nos sites Web et en mesurer la performance;</li>
              <li>pour procéder à l’entretien de nos sites Web, notamment à des fins de sécurité.</li>
            </ul>
            <h3>Plus de précisions sur nos activités de traitement des données</h3>
            <p>
              <u>Répondre aux demandes et aux questions&nbsp;:</u> Des renseignements personnels peuvent nous être
              soumis à différentes fins, par le biais d’interactions directes, ainsi que par l’entremise de nos sites
              Web et d’autres ressources en ligne. À titre d’exemple, il se peut que vous soumettiez une demande
              d’information médicale, que vous vous renseigniez à propos d’un produit ou que vous vous inscriviez à
              l’une de nos listes d’envoi. Dans ces cas, il se peut que nous utilisions les renseignements que vous nous
              fournissez pour répondre à votre demande. Selon la nature de votre demande, il se peut que nous
              recueillions vos coordonnées (par exemple, votre nom, votre adresse postale, votre numéro de téléphone, le
              titre de votre poste), vos champs d’intérêt ou vos préférences (par exemple, des produits ou des domaines
              en particulier), ainsi que toute autre information que vous nous fournissez. Si nous sommes soumis à des
              exigences en matière de divulgation, il se peut que nous traitions vos données afin de nous conformer à
              nos obligations légales.
            </p>
            <p>
              <u>Effectuer des transactions&nbsp;:</u> Vos renseignements personnels peuvent être recueillis afin
              d’exécuter des services que vous avez demandés ou pour mieux comprendre vos champs d’intérêt et vos
              préférences. Dans ces cas, nous pouvons recueillir vos coordonnées, de même que l’historique des
              transactions que vous avez effectuées avec nous (par exemple, l’historique des commandes, les informations
              du compte client). Si vous achetez ou commandez un produit ou un service auprès de nous, nous utilisons
              ces renseignements pour remplir la convention de vente conclue avec vous.
            </p>
            <p className="compact">
              <u>Personnaliser votre expérience&nbsp;:</u> Il se peut que nous recueillions certains renseignements à
              propos de vous et de vos préférences, ainsi que sur l’historique de vos interactions avec nous, afin de
              comprendre votre intérêt pour nos produits et services et ainsi mieux vous servir. Ces renseignements
              peuvent comprendre notamment le mode de correspondance que vous privilégiez et vos préférences à l’égard
              des produits, vos préférences linguistiques, vos préférences en matière de marketing et vos données
              démographiques. Si nous recueillons automatiquement ces renseignements, nous les recueillons et les
              traitons selon nos intérêts commerciaux légitimes. Dans tout autre cas, les renseignements sont recueillis
              et traités sous réserve de votre consentement.
            </p>
            <p>
              <u>Analyser les sites Web et en faire le suivi&nbsp;:</u> Lorsque la loi le permet, nous pouvons combiner
              les renseignements personnels que vous fournissez avec d’autres renseignements que vous avez déjà fournis
              à Roche par le biais de ses sites Web. Lorsque la loi le permet, nous pouvons également combiner les
              renseignements personnels recueillis par le biais de nos sites Web et de nos ressources en ligne avec des
              dossiers hors ligne, ainsi qu’avec des renseignements qui nous sont fournis par de tierces parties. Vous
              trouverez davantage d’information à ce sujet dans la section « Témoins et autres outils de suivi Web »
              ci-dessous.
            </p>
            <p>
              <u>Exercer des activités de pharmacovigilance et répondre aux demandes d’information médicale&nbsp;:</u>{' '}
              Tout renseignement personnel fourni à Roche en lien avec des manifestations indésirables ou dans le cadre
              d’autres activités liées à la pharmacovigilance ne sera utilisé qu’à ces seules fins. De tels
              renseignements sont extrêmement importants pour la santé publique et sont utilisés à des fins de
              détection, d’évaluation, de compréhension et de prévention des manifestations indésirables ou de tout
              autre problème de nature médicale. Nous recueillons et traitons vos données à de telles fins conformément
              à la législation en vigueur en matière de pharmacovigilance et afin de nous conformer à nos obligations
              légales. Tout renseignement personnel fourni à Roche dans le cadre d’une demande d’information médicale
              peut être utilisé dans le but de répondre à cette demande, de faire le suivi de pareilles demandes et de
              tenir une base de données d’informations médicales pour référence. Lorsque la loi l’exige (comme dans le
              cas de la pharmacovigilance), il se peut que nous ayons aussi l’obligation de communiquer les
              renseignements aux autorités réglementaires. Vos renseignements personnels ne seront pas utilisés à
              d’autres fins.
            </p>
            <h3>Aucune utilisation en vue de la vente directe par des tiers</h3>
            <p>
              Nous nous engageons à ne pas vendre ou céder à des tiers les renseignements personnels identificatoires
              que vous nous fournissez en vue de leur utilisation à des fins de vente directe, à moins que nous vous en
              avisions de façon claire et que vous nous donniez votre consentement explicite à un tel partage de vos
              données.
            </p>
            <h3>Destinataires de vos renseignements personnels</h3>
            <p>
              Il est possible que nous communiquions vos renseignements personnels avec les sociétés affiliées de Roche
              ailleurs dans le monde. Les sociétés affiliées de Roche utiliseront vos renseignements personnels aux
              mêmes fins que nous. La liste des sociétés affiliées de Roche est disponible dans le dernier rapport
              annuel, disponible dans la section s’adressant aux investisseurs du site www.roche.com.
            </p>
            <p>
              Il se peut aussi que nous divulguions vos renseignements personnels à des tiers externes, notamment nos
              fournisseurs de services de traitement de données, pour les fins suivantes&nbsp;:
            </p>
            <ul>
              <li>permettre à Roche de réaliser ses opérations commerciales;</li>
              <li>procéder à la maintenance technique de nos sites Web et autres plateformes Web;</li>
              <li>
                faciliter une fusion, un regroupement d’entreprises, un transfert de contrôle ou toute autre
                réorganisation à laquelle participe Roche, ou qui est effectuée en vertu d’un accord financier conclu
                par Roche;
              </li>
              <li>
                pour répondre aux demandes appropriées d’autorités gouvernementales légitimes ou lorsque les lois, les
                ordonnances de tribunaux ou les règlements gouvernementaux applicables l’exigent;
              </li>
              <li>
                lorsque des vérifications internes l’exigent ou pour enquêter sur une plainte ou sur une menace à la
                sécurité, ou y répondre.
              </li>
            </ul>
            <h3>Transfert international de vos renseignements personnels</h3>
            <p>
              Tout renseignement personnel que vous nous fournissez peut être transféré ou stocké dans une autre région
              géographique, dont la réglementation en matière de confidentialité peut différer de celle qui prévaut dans
              votre pays de résidence. Cela signifie que vos renseignements personnels peuvent être envoyés dans un pays
              dont les lois sur la protection des données sont moins contraignantes que celles en vigueur dans votre
              pays. Un tel transfert, le cas échéant, sera effectué conformément aux lois en vigueur. Les renseignements
              personnels ainsi transférés peuvent être assujettis aux lois locales des territoires où ils sont
              recueillis, utilisés, divulgués et/ou stockés, et les autorités gouvernementales et les organismes
              d’application de la loi de ces territoires peuvent y avoir accès.
            </p>
            <h3>Conservation/durée de stockage de vos renseignements personnels</h3>
            <p>
              La durée de stockage de vos renseignements personnels dépend de la raison pour laquelle nous les avons
              recueillis et traités. Dans la plupart des cas, nous conservons ces renseignements pour une période de
              trois (3) ans après notre dernière interaction avec vous. Nous pourrions cependant devoir conserver vos
              renseignements plus longtemps si la loi l’exige. À titre d’exemple, les rapports liés à la
              pharmacovigilance sont conservés au moins dix (10) ans après le retrait d’un produit du dernier pays où il
              était commercialisé.
            </p>
            <h3>Nos sites Web</h3>
            <p>
              Nous faisons appel à certaines informations pour assurer la sécurité de nos sites Web, de nos réseaux
              ainsi que d’autres actifs. Ces informations peuvent comprendre votre adresse IP, votre emplacement
              géographique, les ressources auxquelles vous avez accédé et d’autres informations similaires. Nous
              recueillons ces informations automatiquement, selon nos intérêts commerciaux légitimes. L’utilisation de
              certaines sections de notre site Web nécessite la saisie de renseignements personnels, notamment les
              sondages, l’inscription et les fonctionnalités de partage de contenu (les liens « Envoi d’un courriel à un
              ami »). Roche et ses partenaires d’affaires ne recueillent ces renseignements que si vous leur fournissez
              volontairement. Veuillez noter que certaines fonctionnalités de ce site pourraient ne pas être accessibles
              si vous choisissez de ne pas fournir certains renseignements personnels. Tout renseignement personnel que
              vous nous fournissez sera utilisé conformément au présent énoncé de confidentialité.
            </p>
            <b>Témoins et autres outils de suivi Web</b>
            <p>
              Nos sites Web font appel à des technologies comme les témoins, les pixels invisibles et autres
              technologies de suivi nous permettant de vous fournir un contenu adapté à vos champs d’intérêt et à vos
              préférences, déterminés d’après votre historique d’activité sur le site. Veuillez consulter notre
              Politique relative aux témoins pour de plus amples renseignements.
            </p>
            <h3>Sites Web que nous ne détenons ou ne contrôlons pas</h3>
            <p>
              Nos sites Web peuvent contenir des liens vers des sites Web de tierces parties. Nous ne cautionnons aucun
              site Web ni aucune ressource de tierce partie ni n’assumons aucune responsabilité quant à leur contenu, et
              le présent énoncé de confidentialité ne s’applique à aucun site non affilié à Roche, même si vous y
              accédez à partir d’un lien placé sur notre site. Vous devriez passer en revue les politiques de
              confidentialité de tout site tiers avant d’y fournir quelque information que ce soit.
            </p>
            <h3>Mineurs</h3>
            <p>
              Nous ne recueillons sciemment aucun renseignement personnel de quiconque âgé de moins de 16 ans sans le
              consentement préalable et vérifiable de son représentant légal. Ce représentant légal peut avoir le droit,
              sur demande, de consulter les renseignements fournis par cet enfant et d’exiger qu’ils soient supprimés.
              En outre, tout mineur doit d’abord obtenir l’autorisation de ses parents ou de son tuteur (représentant
              légal) pour utiliser ou divulguer tout renseignement personnel sur notre site Web ou nos ressources en
              ligne. Ce site Web n’est pas conçu pour être utilisé par des enfants de moins de 16 ans.
            </p>
            <h3>Sécurité des données</h3>
            <p>
              Roche a adopté des mesures de sécurité, des règles et d’autres procédures technologiques pour protéger vos
              données personnelles de l’accès non autorisé, de la mauvaise utilisation, de la divulgation, de la perte
              ou de la destruction. Afin d’assurer la confidentialité de vos renseignements, Roche utilise des pare-feux
              et des protections par mots de passe qui répondent aux normes de l’industrie. Il vous incombe cependant de
              vous assurer que l’ordinateur que vous utilisez est adéquatement sécurisé et qu’il est protégé contre les
              logiciels malveillants comme les chevaux de Troie, les virus et les vers informatiques. Vous devez être
              conscient que sans l’adoption de mesures de sécurité adéquates (p. ex. la configuration sécurisée du
              navigateur, un logiciel antivirus à jour, l’installation d’un pare-feu personnel et la non-utilisation de
              logiciels de sources douteuses), les données et les mots de passe que vous utilisez pour protéger l’accès
              à vos données risquent d’être divulgués à des tiers non autorisés.
            </p>
            <h3>Accès</h3>
            <p>
              Vous avez le droit de demander accès à vos renseignements personnels et d’en demander la correction si
              vous les croyez erronés. Si vous croyez que vos renseignements personnels sont inexacts, ou que vous
              voulez y avoir accès, vous pouvez effectuer une demande d’accès en utilisant les coordonnées qui figurent
              ci-dessous.
            </p>
            <p>
              Nous nous efforçons de vous donner rapidement accès à vos renseignements personnels. Cependant, pour ce
              faire, il se peut que nous vous demandions tout d’abord de vous identifier à notre satisfaction. De plus,
              il y a des circonstances dans lesquelles un tel accès ne peut être accordé. À titre d’exemple, nous
              n’accorderons pas un tel accès si cela risque de mener à la divulgation de renseignements personnels d’une
              autre personne et que celle-ci n’y consent pas, ou que ces renseignements sont assujettis à un privilège
              ou à toute autre forme de restriction juridique. Dans de tels cas, nous vous aviserons de la raison pour
              laquelle nous ne pouvons vous donner accès à vos renseignements personnels.
            </p>
            <h3>Mises à jour du présent énoncé de confidentialité</h3>
            <p>
              Nous pouvons, de temps à autre, réviser le présent énoncé de confidentialité. Toute modification au
              présent énoncé de confidentialité figurera sur cette page. Roche recommande de passer régulièrement en
              revue le présent énoncé de confidentialité afin de prendre connaissance de toute modification qui y aurait
              été apportée. La date de la dernière révision du présent énoncé figure au début du document.
            </p>
            <h3>Comment communiquer avec Roche</h3>
            <p>
              Si vous avez des questions ou si vous désirez que Roche modifie ou supprime votre profil, veuillez
              communiquer avec nous en nous écrivant (par courrier postal) à l’adresse suivante:
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Produits pharmaceutiques
                      <br />
                      Responsable de la protection des renseignements personnels
                      <br />
                      7070 Mississauga Road
                      <br />
                      Mississauga (Ontario)
                      <br />
                      Canada
                      <br />
                      Tél. : 1-800-561-1759
                    </p>
                  </td>
                  <td>
                    <p>
                      Produits diagnostiques
                      <br />
                      Responsable de la protection des renseignements personnels
                      <br />
                      201, boul. Armand-Frappier
                      <br />
                      Laval (Québec)
                      <br />
                      H7V 4A2
                      <br />
                      Tél. : 1-800-361-2070
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <p>
              Ce site Web présente de l’information destinée aux résidents du Canada seulement et peut contenir des
              détails ou des renseignements sur des produits qui ne pourraient être autrement accessibles ou qui ne sont
              pas valides dans d’autres pays. Nous n’assumons aucune responsabilité envers les utilisateurs de
              l’extérieur du Canada qui consultent certaines parties de ce site Web, puisque ces dernières peuvent être
              invalides dans leur pays ou non conformes aux lois, aux règlements ou aux normes qui y sont en vigueur.
            </p>
          </div>
        </TranslatedBlock>
        <Annotation />
      </Layout>
    );
  }
}
